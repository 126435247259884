import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Inicio from './pages/Inicio';
import Estoque from './pages/Estoque';
import Veiculo from './pages/Veiculo';
import Financiamento from './pages/Financiamento';
import XmlPage from './pages/XmlPage';



class App extends Component {
  render(){
    return (
      <Router>
          <Switch>  
              <Route path='/' exact component={Inicio} />                  
              <Route path='/veiculo/:idVeiculo' component={Veiculo} />                 
              <Route path='/estoque' component={Estoque} />    
              <Route path='/financiamento' component={Financiamento} />  
              <Route path='/xmlfacebook' component={XmlPage} />
          </Switch> 
      </Router>
    );
  }
}

export default App;
